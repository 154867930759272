// import React, { useState, useEffect, useRef } from "react";
// import { db, auth } from "../../components/configuration/firebaseConfig";
// import { ref, remove, query, orderByChild, equalTo, get } from "firebase/database";
// import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
// import logo from '../../images/logo.png';
// import { NavLogo } from '../../components/navbar/navbarElement';
// import './RemoveDetails.css';

// const RemoveDetails = () => {
//   const [name, setName] = useState("");
//   const [phone, setPhone] = useState("");
//   const [email, setEmail] = useState("");
//   const [otp, setOtp] = useState("");
//   const [confirmationResult, setConfirmationResult] = useState(null);
//   const [message, setMessage] = useState("");
//   const [isOtpSent, setIsOtpSent] = useState(false);
//   const recaptchaContainerRef = useRef(null);

//    // Function to setup reCAPTCHA
//    const setupRecaptcha = () => {
//     return new Promise((resolve, reject) => {
//       try {
//         // Ensure reCAPTCHA is initialized on the specified DOM element
//         const verifier = new RecaptchaVerifier(recaptchaContainerRef.current, {
//           size: 'normal', // 'invisible' for production
//           callback: (response) => {
//             console.log('reCAPTCHA solved:', response);
//             resolve(response);
//           },
//           'expired-callback': () => {
//             setMessage('Recaptcha expired, please try again.');
//             reject('Recaptcha expired');
//           }
//         }, auth);
//         verifier.render().then(() => {
//           console.log("reCAPTCHA rendered successfully");
//           resolve();
//         });
//         window.recaptchaVerifier = verifier;  // Save the instance globally if needed
//       } catch (error) {
//         console.error('Error setting up reCAPTCHA:', error.message);
//         reject(error);
//       }
//     });
//   };

//   // Function to send OTP
//   const sendOtp = async () => {
//     try {
//       await setupRecaptcha();  // Call to set up reCAPTCHA
//       const phoneNumber = `+91${phone.trim()}`;
//       console.log("Sending OTP to:", phoneNumber);

//       const result = await signInWithPhoneNumber(auth, phoneNumber, window.recaptchaVerifier);
//       setConfirmationResult(result);
//       setMessage(`OTP sent to ${phone}`);
//       setIsOtpSent(true);
//     } catch (error) {
//       console.error("Error sending OTP:", error.message);
//       setMessage("Failed to send OTP. Please try again.");
//     }
//   };


//   // Effect hook to ensure reCAPTCHA is initialized on mount
//   useEffect(() => {
//     if (recaptchaContainerRef.current) {
//       setupRecaptcha();  // Initialize reCAPTCHA on mount
//     }
//   }, []);

//   // Function to verify OTP and delete user details
//   const verifyOtpAndDelete = async () => {
//     if (!confirmationResult) {
//       setMessage("Please send OTP first.");
//       return;
//     }

//     try {
//       await confirmationResult.confirm(otp);
//       const userRef = query(ref(db, 'India/User_Details'), orderByChild('MobileNoOfUser'), equalTo(phone));
//       const snapshot = await get(userRef);

//       if (snapshot.exists()) {
//         const userId = Object.keys(snapshot.val())[0];
//         const deleteRef = ref(db, `India/User_Details/${userId}`);
//         await remove(deleteRef);
//         setMessage("User details successfully deleted.");
//       } else {
//         setMessage("User not found.");
//       }
//     } catch (error) {
//       console.error("Error verifying OTP or deleting user details:", error.message);
//       setMessage("Incorrect OTP or error deleting details. Please try again.");
//     }
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     const phonePattern = /^[6-9]\d{9}$/; // Validate Indian phone numbers
//     if (!phonePattern.test(phone.trim())) {
//       setMessage("Please enter a valid phone number.");
//       return;
//     }

//     try {
//       await sendOtp();
//     } catch (error) {
//       setMessage("Error sending OTP, please try again.");
//     }
//   };

//   return (
//     <div>
//       <div style={{ backgroundColor: 'black', padding: '10px' }}>
//         <NavLogo>
//           <img src={logo} alt='logo' style={{width:'40px', height:'40px', marginRight:'10px'}} />
//           The Health App
//         </NavLogo>
//       </div>

//       <h2>Remove Details</h2>
//       <form onSubmit={handleSubmit}>
//         <div>
//           <label>Registered Name:</label>
//           <input
//             type="text"
//             value={name}
//             onChange={(e) => setName(e.target.value)}
//             required
//           />
//         </div>
//         <div>
//           <label>Registered Contact Number:</label>
//           <input
//             type="text"
//             value={phone}
//             onChange={(e) => setPhone(e.target.value)}
//             required
//           />
//         </div>
//         <div>
//           <label>Registered Email ID:</label>
//           <input
//             type="email"
//             value={email}
//             onChange={(e) => setEmail(e.target.value)}
//             required
//           />
//         </div>
//         <button type="submit" disabled={isOtpSent}>Send OTP</button>
//       </form>

//       {isOtpSent && (
//         <>
//           <h3>Enter OTP</h3>
//           <input
//             type="text"
//             value={otp}
//             onChange={(e) => setOtp(e.target.value)}
//             required
//           />
//           <button onClick={verifyOtpAndDelete}>Verify OTP & Delete</button>
//         </>
//       )}

//       <div ref={recaptchaContainerRef} id="recaptcha-container"></div>

//       <p>{message}</p>
//     </div>
//   );
// };

// export default RemoveDetails;


import React, { useState } from "react";
import { db, auth } from "../../components/configuration/firebaseConfig";
import { ref, remove, query, orderByChild, equalTo, get } from "firebase/database";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import logo from '../../images/logo.png';
import { NavLogo } from '../../components/navbar/navbarElement';
import './RemoveDetails.css';

const RemoveDetails = () => {
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [confirmationResult, setConfirmationResult] = useState(null);
  const [message, setMessage] = useState("");
  const [isOtpSent, setIsOtpSent] = useState(false);
  // const recaptchaVerifierRef = useRef(null);

  // Function to setup reCAPTCHA
  const setupRecaptcha = () => {
    // Ensure that the recaptcha container is in the DOM
  if (!document.getElementById('recaptcha-container')) {
    console.error('Recaptcha container not found in the DOM.');
    return;
  }
  try{
    if(!window.recaptchaVerifier){
        window.recaptchaVerifier = new RecaptchaVerifier(auth,
      'recaptcha-container', 
      {
        size: 'invisible',  //normal for development and invisible for production
        callback: (response) => {
          console.log('reCAPTCHA solved:', response);
        },
        'expired-callback': () => {
          setMessage('reCAPTCHA expired. Please try again.');
        }
      }
      ,auth
    );
  

    // Render the recaptcha widget on the page
    window.recaptchaVerifier.render().then((widgetId) => {
      console.log('Recaptcha rendered successfully with widget ID:', widgetId);
    });
  }
}
  catch (error) {
    console.error('Error setting up RecaptchaVerifier:', error.message);
  }
  };

  // Function to send OTP
  const sendOtp = async () => {
    try {

       // Bypass recaptcha for testing purposes
    // auth.settings.appVerificationDisabledForTesting = true; // Only for testing

       setupRecaptcha();  // Setup reCAPTCHA
      const phoneNumber = `+91${phone.trim()}`;
      console.log("Sending OTP to:", phoneNumber);
      const appVerifier = window.recaptchaVerifier; // Use the recaptcha verifier instance

      const result = await signInWithPhoneNumber(auth, phoneNumber,appVerifier);
      setConfirmationResult(result);
      setMessage(`OTP sent to ${phone}`);
      setIsOtpSent(true);
    } catch (error) {
      console.error("Error sending OTP:", error.message);
      setMessage("Failed to send OTP. Please try again.");
    }
  };

  // Function to verify OTP and delete user details
  const verifyOtpAndDelete = async () => {
    if (!confirmationResult) {
      setMessage("Please send OTP first.");
      return;
    }

    try {
      await confirmationResult.confirm(otp);  // Verifying OTP
      const userRef = query(ref(db, 'India/User_Details'), orderByChild('MobileNoOfUser'), equalTo(phone));
      const snapshot = await get(userRef);

      if (snapshot.exists()) {
        const userId = Object.keys(snapshot.val())[0];
        const deleteRef = ref(db, `India/User_Details/${userId}`);
        await remove(deleteRef);
        setMessage("User details successfully deleted.");
      } else {
        setMessage("User not found.");
      }
    } catch (error) {
      console.error("Error verifying OTP or deleting user details:", error.message);
      setMessage("Incorrect OTP or error deleting details. Please try again.");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const phonePattern = /^[6-9]\d{9}$/; // Validate Indian phone numbers
    if (!phonePattern.test(phone.trim())) {
      setMessage("Please enter a valid phone number.");
      return;
    }

    try {
      await sendOtp();
    } catch (error) {
      setMessage("Error sending OTP. Please try again.");
    }
  };

  return (
    <div>
      <div style={{ backgroundColor: 'black', padding: '10px' }}>
        <NavLogo>
          <img src={logo} alt='logo' style={{ width: '40px', height: '40px', marginRight: '10px' }} />
          The Health App
        </NavLogo>
      </div>

      <h2>Remove Details</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label>Registered Name:</label>
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
        </div>
        <div>
          <label>Registered Contact Number:</label>
          <input
            type="text"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            required
          />
        </div>
        <div>
          <label>Registered Email ID:</label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <button type="submit" disabled={isOtpSent}>Send OTP</button>
      </form>

      {isOtpSent && (
        <>
          <h3>Enter OTP</h3>
          <input
            type="text"
            value={otp}
            onChange={(e) => setOtp(e.target.value)}
            required
          />
          <button onClick={verifyOtpAndDelete}>Verify OTP & Delete</button>
        </>
      )}

      <div  id="recaptcha-container"></div>

      <p>{message}</p>
    </div>
  );
};

export default RemoveDetails;
