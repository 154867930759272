// // firebaseConfig.js
// import { initializeApp } from "firebase/app";
// import { getDatabase} from "firebase/database";
// import { getAuth } from "firebase/auth";
// // import {RecaptchaVerifier, signInWithPhoneNumber} from "firebase/auth"

// const firebaseConfig = {
//   apiKey:"AIzaSyDf5RXSkLMVrdWPHFay9DKUxn4TFTEJN2U",
//   authDomain: "health-app-4c3da.firebaseapp.com",
//   databaseURL: "https://health-app-4c3da-default-rtdb.firebaseio.com",
//   projectId: "health-app-4c3da",
//   storageBucket: "health-app-4c3da.appspot.com",
//   messagingSenderId:  "604234265670",
//   appId: "1:604234265670:web:0e482b162e44b611738647",
//   measurementId: "G-8860BK7BFL"
// };

// // Initialize Firebase
// const app = initializeApp(firebaseConfig);
// const db = getDatabase(app);

// // Initialize Firebase Auth
// const auth = getAuth(app);
// // auth.languageCode = 'en';

// // Disable app verification for testing (only in development environment)
// if (process.env.NODE_ENV === 'development') {
//   auth.settings.appVerificationDisabledForTesting = true;
// }

// // export default db;
// export { db, auth};

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
import { getDatabase } from "firebase/database";
import { getAuth } from "firebase/auth";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {


  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DB_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,

};
// console.log('API Key:', process.env.REACT_APP_FIREBASE_API_KEY);
// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getDatabase(app)
const auth=getAuth(app)
// const analytics = getAnalytics(app);

export {db,auth};